import { RSAA } from "redux-api-middleware";
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import { fetchSeqCount } from "redux/orders";
import { getOrdersSelectedTabIndex } from "redux/root-reducer";
import { NEXT_ACTION } from "redux/middlewares/next";
import { RETURN_SCRIPT_FAILURE_UPDATE_MCS } from "redux/confirm-dialog";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;

// ACTIONS
export const SHOW_RETURN_SCRIPT_DIALOG = "RETURN_SCRIPT/SHOW_DIALOG";
export const HIDE_RETURN_SCRIPT_DIALOG = "RETURN_SCRIPT/HIDE_DIALOG";
export const CHANGE_INPUT_TEXT = "RETURN_SCRIPT/CHANGE_INPUT_TEXT";
export const RETURN_SCRIPT_REQUEST = "RETURN_SCRIPT/REQUEST";
export const RETURN_SCRIPT_RECEIVE = "RETURN_SCRIPT/RECEIVE";
export const RETURN_SCRIPT_FAILURE = "RETURN_SCRIPT/FAILURE";

// ACTION CREATORS
export const showReturnPrescriptionDialog = (orderId, rxToken) => ({
  type: SHOW_RETURN_SCRIPT_DIALOG,
  orderId,
  rxToken
});
export const hideReturnPrescriptionDialog = () => ({
  type: HIDE_RETURN_SCRIPT_DIALOG
});
export const changeReturnPrescriptionInputText = text => ({
  type: CHANGE_INPUT_TEXT,
  text
});
export const returnPrescription = (
  orderId,
  prescriptionCode,
  fromUpdateMCS = false,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/order/${orderId}/return`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ prescriptionCode }),
    types: [
      RETURN_SCRIPT_REQUEST,
      RETURN_SCRIPT_RECEIVE,
      fromUpdateMCS ? RETURN_SCRIPT_FAILURE_UPDATE_MCS : RETURN_SCRIPT_FAILURE
    ],
    options: { addAuth: true }
  }
});

export const returnPrescriptionCFRejected = (
  orderId,
  prescriptionCode,
  endpoint = ORDERS_API_ENDPOINT
) => ({
  [RSAA]: {
    endpoint: `${endpoint}/order/${orderId}/return`,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ prescriptionCode }),
    types: [
      RETURN_SCRIPT_REQUEST,
      {
        type: RETURN_SCRIPT_RECEIVE,
        payload: (action, state, res) =>
          res.json().then(() => ({
            [NEXT_ACTION]: fetchSeqCount(getOrdersSelectedTabIndex(state) - 1)
          }))
      },
      RETURN_SCRIPT_FAILURE
    ],
    options: { addAuth: true }
  }
});

// SELECTORS
export const getIsOpen = state => state.isOpen;
export const getIsLoading = state => state.isLoading;
export const getShowError = state => state.showError;
export const getErrorMessage = state => state.errorMessage;
export const getInputText = state => state.inputText;
export const getOrderId = state => state.orderId;

// REDUCERS
const isOpen = handleActions(
  {
    [HIDE_RETURN_SCRIPT_DIALOG]: () => false,
    [SHOW_RETURN_SCRIPT_DIALOG]: () => true,
    [RETURN_SCRIPT_RECEIVE]: () => false
  },
  false
);
const isLoading = handleActions(
  {
    [RETURN_SCRIPT_REQUEST]: () => true,
    [RETURN_SCRIPT_RECEIVE]: () => false,
    [RETURN_SCRIPT_FAILURE]: () => false
  },
  false
);
const showError = handleActions(
  {
    [RETURN_SCRIPT_FAILURE]: () => true,
    [SHOW_RETURN_SCRIPT_DIALOG]: () => false
  },
  false
);
const errorMessage = handleActions(
  {
    [RETURN_SCRIPT_FAILURE]: (state, action) =>
      action.payload.response.error
        ? action.payload.response.error.message
        : "Could not get reason from server. Contact dev team.",
    [SHOW_RETURN_SCRIPT_DIALOG]: () => ""
  },
  ""
);
const orderId = handleActions(
  {
    [SHOW_RETURN_SCRIPT_DIALOG]: (state, action) => action.orderId,
    [HIDE_RETURN_SCRIPT_DIALOG]: () => null
  },
  null
);
const inputText = handleActions(
  {
    [CHANGE_INPUT_TEXT]: (state, action) => action.text,
    [SHOW_RETURN_SCRIPT_DIALOG]: (state, action) => action.rxToken || ""
  },
  ""
);

export default combineReducers({
  isOpen,
  isLoading,
  showError,
  errorMessage,
  orderId,
  inputText
});
