// Actions
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { RSAA } from "redux-api-middleware";
import { NEXT_ACTION } from "redux/middlewares/next";
import { fetchSeqCount } from "redux/orders";
import { getOrdersSelectedTabIndex } from "redux/root-reducer";

const ORDERS_API_ENDPOINT = process.env.REACT_APP_ORDERS_API;
export const SHOW_MARK_ORDER_MCS = "SHOW_MARK_ORDER_MCS";
export const HIDE_MARK_ORDER_MCS = "HIDE_MARK_ORDER_MCS";
export const ADD_MEDICINE = "ADD_MEDICINE";
export const REMOVE_MEDICINE = "REMOVE_MEDICINE";
export const MARK_ORDER_MCS_REQUEST = "MARK_ORDER_MCS_REQUEST";
export const MARK_ORDER_MCS_RECEIVE = "MARK_ORDER_MCS_RECEIVE";
export const MARK_ORDER_MCS_FAILURE = "MARK_ORDER_MCS_FAILURE";

// Action Creators
export const showMarkOrderMcs = (orderId, medicines) => ({
  type: SHOW_MARK_ORDER_MCS,
  orderId,
  medicines
});

export const hideMarkOrderMcs = () => ({
  type: HIDE_MARK_ORDER_MCS
});

export const addMedicine = (medicineIndex, selectedMedicines) => ({
  type: ADD_MEDICINE,
  medicineIndex,
  selectedMedicines
});

export const removeMedicine = (medicineIndex, selectedMedicines) => ({
  type: REMOVE_MEDICINE,
  medicineIndex,
  selectedMedicines
});

export const markMCS = (
  orderId,
  medicines,
  selectedMedicineIndexes,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const selectedMedicines = medicines
    .filter((med, i) => selectedMedicineIndexes.includes(i))
    .map(med => ({ medicineItemId: med.medicineItemId }));
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${orderId}/markmcs`,
      method: "POST",
      body: JSON.stringify({ medicines: selectedMedicines }),
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        MARK_ORDER_MCS_REQUEST,
        MARK_ORDER_MCS_RECEIVE,
        MARK_ORDER_MCS_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

export const markMCSCFRejected = (
  orderId,
  medicines,
  selectedMedicineIndexes,
  apiEndpoint = ORDERS_API_ENDPOINT
) => {
  const selectedMedicines = medicines
    .filter((med, i) => selectedMedicineIndexes.includes(i))
    .map(med => ({ medicineItemId: med.medicineItemId }));
  return {
    [RSAA]: {
      endpoint: `${apiEndpoint}/order/${orderId}/markmcs`,
      method: "POST",
      body: JSON.stringify({ medicines: selectedMedicines }),
      headers: {
        "Content-Type": "application/json"
      },
      types: [
        MARK_ORDER_MCS_REQUEST,
        {
          type: MARK_ORDER_MCS_RECEIVE,
          payload: (action, state, res) =>
            res.json().then(() => ({
              [NEXT_ACTION]: fetchSeqCount(getOrdersSelectedTabIndex(state) - 1)
            }))
        },
        MARK_ORDER_MCS_FAILURE
      ],
      options: { addAuth: true }
    }
  };
};

// Selectors
export const getIsOpen = state => state.isOpen;
export const getSelectedMedicines = state => state.selectedMedicines;
export const getCanSubmit = state => state.canSubmit;
export const getIsUpdating = state => state.isUpdating;
export const getMedicines = state => state.medicines;
export const getOrderId = state => state.orderId;

// Reducers
const isOpen = handleActions(
  {
    [SHOW_MARK_ORDER_MCS]: () => true,
    [HIDE_MARK_ORDER_MCS]: () => false,
    [MARK_ORDER_MCS_RECEIVE]: () => false
  },
  false
);

const selectedMedicines = handleActions(
  {
    [ADD_MEDICINE]: (medicines, action) => {
      const newMedicines = [...medicines];
      newMedicines.push(action.medicineIndex);
      return newMedicines.sort();
    },
    [REMOVE_MEDICINE]: (medicines, action) => {
      const newMedicines = [...medicines];
      return newMedicines.filter(med => med !== action.medicineIndex).sort();
    },
    [SHOW_MARK_ORDER_MCS]: () => [],
    [HIDE_MARK_ORDER_MCS]: () => []
  },
  []
);

const medicines = handleActions(
  {
    [SHOW_MARK_ORDER_MCS]: (_, action) =>
      action.medicines.map(med => ({
        desc: med.brandDesc ? med.brandDesc : med.prodDesc,
        ampId: med.packages && med.packages[0].ampId,
        medicineItemId: med.medicineItemId
      })),
    [HIDE_MARK_ORDER_MCS]: () => []
  },
  []
);

const canSubmit = handleActions(
  {
    [ADD_MEDICINE]: () => true,
    [REMOVE_MEDICINE]: (state, action) =>
      action.selectedMedicines.length - 1 > 0,
    [SHOW_MARK_ORDER_MCS]: () => false,
    [HIDE_MARK_ORDER_MCS]: () => false
  },
  false
);

const isUpdating = handleActions(
  {
    [MARK_ORDER_MCS_REQUEST]: () => true,
    [MARK_ORDER_MCS_RECEIVE]: () => false,
    [MARK_ORDER_MCS_FAILURE]: () => false,
    [SHOW_MARK_ORDER_MCS]: () => false,
    [HIDE_MARK_ORDER_MCS]: () => false
  },
  false
);

const orderId = handleActions(
  {
    [SHOW_MARK_ORDER_MCS]: (_, action) => action.orderId,
    [HIDE_MARK_ORDER_MCS]: () => 0
  },
  0
);

export default combineReducers({
  isOpen,
  selectedMedicines,
  medicines,
  canSubmit,
  isUpdating,
  orderId
});
