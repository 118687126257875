import { handleActions } from "redux-actions";

// Actions
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

// Action Creators
export const sendNotification = ({ message, duration = 3000 }) => ({
  type: SEND_NOTIFICATION,
  message,
  duration
});

export const closeNotification = () => ({
  type: HIDE_NOTIFICATION
});

// Selectors
export const getIsVisible = state => state.visible;
export const getMessage = state => state.message;
export const getDuration = state => state.duration;

// Reducers
export default handleActions(
  {
    [SEND_NOTIFICATION]: (state, action) => ({
      ...state,
      visible: true,
      message: action.message,
      duration: action.duration
    }),
    [HIDE_NOTIFICATION]: state => ({ ...state, visible: false })
  },
  {
    visible: false,
    message: "",
    duration: 0
  }
);
