export interface OrdersEndointOptions {
  page?: number;
  filters?: string[];
  showExpired?: boolean;
  vmpIds?: string[];
  ampIds?: string[];
  dateFilter?: string[];
  dateFilterPriority?: boolean;
  userId?: string;
  ordersPerPage?: number;
  splitsCount?: number;
  splitIndex?: number;
  orderSequence?: number;
  tab?: string;
  splitAlpha?: string;
  apiEndpoint?: string;
}

export interface OrdersQuery {
  page?: number;
  status?: string;
  showExpired?: boolean;
  vmpId?: string;
  ampId?: string;
  userId?: string;
  ordersPerPage?: number;
  splitsCount?: number;
  splitIndex?: number;
  orderSequence?: number;
  tab?: string;
  splitAlpha?: string;
  datesFilter?: string;
  priorityFilter?: boolean;
}

export interface AdditionalMedInfo {
  contraceptive?: boolean;
  costUnits?: number;
}

export interface Package {
  ampId: string;
  barcodes: string[];
}

export interface Medicine {
  vmpId: string;
  ampId: string;
  brandName?: string;
  brandDesc?: string;
  prodDesc: string;
  additional?: AdditionalMedInfo;
  packages: Package[];
  isRestricted: boolean;
  form: string;
  drugName: string;
}

export interface Patient {
  userId: string;
  firstName: string;
  lastName: string;
  dob: string;
  address: string;
  phone: string;
  email: string;
}

export interface MedicineQuantities {
  [key: string]: {
    count: number;
    unitOfMeasure: string;
  };
}

export enum ContactMethod {
  FAX = "fax",
  EMAI = "email",
  NONE = ""
}

export interface Additional {
  telephone: string;
  email: string;
  fax: string;
  eps: boolean;
  againstDigitalPharmacy: boolean;
  contactPreference: ContactMethod;
  lastUpdated: string;
  notes: string;
}

export interface GP {
  id: string;
  name: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postcode: string;
  latitude: number;
  longitude: number;
  additional?: Additional;
}

export interface Invoice {
  invoiceId: number;
  status: string;
  exemptionCode: string;
  exemptionEvidenceSkipped: boolean;
}

export enum ShippingClass {
  BY_24 = "24",
  BY_48 = "48",
  BY_TRACKED_24 = "tracked24",
  BY_TRACKED_48 = "tracked48",
  BY_GUARANTEED_1PM = "guaranteed1pm"
}

export interface Order {
  id: number;
  requestId: number;
  createdDate: string;
  lastModifiedDate: string;
  lastEventName: string;
  isModified: boolean;
  Status: string;
  medicines: Medicine[];
  patient: Patient;
  medicineQuantities?: MedicineQuantities;
  gp: GP;
  pharmacyId: string;
  pharmacyName: string;
  invoice: Invoice;
  trackingCode: string;
  courier: string;
  trackingUrl: string;
  deliveryAddress: string;
  notes: string;
  mcs: boolean;
  shippingClass: ShippingClass;
  shippingSigned: boolean;
  priority: boolean;
  batchId: string;
  ccBranchId?: string;
  rxToken?: string;
}
