import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import QRCode from "qrcode.react";
import moment from "moment";
import {
  getManualLineStartIsLoadingNext,
  getManualLineStartOrders,
  getManualLineStartBarcode,
  getManualLineStartUser,
  getManualLineStartSelectedOrderIds
} from "../../redux/root-reducer";
import { loadNext, toggleOrderSelection } from "../../redux/manual-line-start";
import { push } from "connected-react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    flexGrow: 1,
    padding: 16
  },
  orderPaper: {
    flexGrow: 1,
    marginBottom: 16,
    padding: 20
  }
};

const makeMedicineCopy = m => {
  let str = "- ";
  str += m.brandDesc || m.prodDesc;
  if (m.quantity && m.quantity.count) {
    str += ` / ${m.quantity.count} ${m.quantity.unitOfMeasure}`;
  }
  return str;
};

const OrderRow = withStyles(styles)(
  ({
    classes,
    orderId,
    medicines,
    exemptionCodes,
    selected,
    priority,
    onToggle
  }) => (
    <Paper className={classes.orderPaper} onClick={() => onToggle(orderId)}>
      <Grid container spacing={8}>
        <Grid item>
          <Checkbox checked={selected} onChange={() => onToggle(orderId)} />
        </Grid>
        <Grid item className="t_order">
          {priority && (
            <Typography id={`t_priority_${orderId}`} color="error">
              Priority
            </Typography>
          )}
          <Typography>{`Order #${orderId}`}</Typography>
          {medicines.map(makeMedicineCopy).map((m, index) => (
            <Typography id={`t_order_${orderId}_med_${index}`} key={m}>
              {m}
            </Typography>
          ))}
          <Typography id="t_exemptionCodes">
            {`Exemption Codes: ${
              exemptionCodes && exemptionCodes.length
                ? exemptionCodes
                    .map(s => (s === "*" ? "RTEC approved" : s))
                    .join(", ")
                : "none"
            }`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
);

const RightColumn = ({
  classes,
  user,
  barcode,
  loadingNext,
  onNext,
  onViewUserProfile
}) => (
  <>
    <Paper className={classes.orderPaper}>
      <Typography id="t_user_name" variant="subtitle1">
        {user.name}
      </Typography>
      <Typography id="t_user_dob" variant="body1">
        {moment(user.dob).format("DD/MM/YYYY")}
      </Typography>
      <Typography variant="subtitle1">Address</Typography>
      <Typography id="t_user_address1">{user.address.address1}</Typography>
      <Typography id="t_user_address2">{user.address.address2}</Typography>
      <Typography id="t_user_address3">{user.address.address3}</Typography>
      <Typography id="t_user_address4">{user.address.address4}</Typography>
      <Typography id="t_user_postcode">{user.address.postcode}</Typography>
      <Button
        id="t_user_view_profile"
        variant="contained"
        color="primary"
        onClick={() => onViewUserProfile(user.id)}
      >
        View
      </Button>
    </Paper>
    <Paper className={classes.orderPaper}>
      <Typography>Select the orders to batch and scan this barcode</Typography>
      <div style={{ textAlign: "center" }}>
        <QRCode size={100} value={barcode} />
      </div>
    </Paper>
    <Paper className={classes.orderPaper}>
      <Typography>Click Next when you have scanned the barcode</Typography>
      {loadingNext ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <Button variant="contained" color="primary" onClick={() => onNext()}>
          Next
        </Button>
      )}
    </Paper>
  </>
);

const ManualLineStartComponent = ({
  classes,
  selectedOrderIds = [],
  onNext,
  barcode,
  orders,
  user,
  onToggleOrder,
  onViewUserProfile,
  loadingNext
}) => {
  useEffect(() => {
    onNext();
  }, [onNext]);
  return (
    <Grid container spacing={32} className={classes.root}>
      <Grid item xs={6}>
        {orders.map(o => (
          <OrderRow
            key={o.id}
            orderId={o.id}
            medicines={o.medicines}
            priority={o.priority}
            exemptionCodes={o.exemptionCodes}
            selected={selectedOrderIds.includes(o.id)}
            onToggle={onToggleOrder}
          />
        ))}
      </Grid>
      <Grid item xs={6}>
        {user && (
          <RightColumn
            classes={classes}
            user={user}
            barcode={barcode}
            onNext={onNext}
            loadingNext={loadingNext}
            onViewUserProfile={onViewUserProfile}
          />
        )}
      </Grid>
    </Grid>
  );
};

ManualLineStartComponent.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    address: PropTypes.shape({
      address1: PropTypes.string.isRequired,
      address2: PropTypes.string.isRequired,
      address3: PropTypes.string.isRequired,
      address4: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired
    }).isRequired
  }),
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      priority: PropTypes.bool.isRequired,
      exemptionCodes: PropTypes.arrayOf(PropTypes.string),
      medicines: PropTypes.arrayOf(
        PropTypes.shape({
          brandDesc: PropTypes.string,
          prodDesc: PropTypes.string.isRequired,
          quantity: PropTypes.shape({
            count: PropTypes.number,
            unitOfMeasure: PropTypes.string
          })
        })
      )
    })
  ),
  selectedOrderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  loadingNext: PropTypes.bool.isRequired,
  onToggleOrder: PropTypes.func.isRequired, // called with order id
  onNext: PropTypes.func.isRequired,
  onViewUserProfile: PropTypes.func.isRequired // called with user id
};

export const ManualLineStart = withStyles(styles)(ManualLineStartComponent);

const mapStateToProps = state => ({
  loadingNext: getManualLineStartIsLoadingNext(state),
  barcode: getManualLineStartBarcode(state),
  user: getManualLineStartUser(state),
  orders: getManualLineStartOrders(state),
  selectedOrderIds: getManualLineStartSelectedOrderIds(state)
});

const mapDispatchToProps = {
  onToggleOrder: toggleOrderSelection,
  onNext: loadNext,
  pushRoute: push
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onViewUserProfile: id => dispatchProps.pushRoute(`/user/${id}`),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ManualLineStart);
