import { connect } from "react-redux";
import {
  addMedicine,
  hideMarkOrderMcs,
  markMCS,
  markMCSCFRejected,
  removeMedicine
} from "../../redux/mark-order-mcs-dialog";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import LoadingButton from "../common/loading-button";
import DialogActions from "@material-ui/core/DialogActions";
import {
  getMarkOrderMcsDialogCanSubmit,
  getMarkOrderMcsDialogIsOpen,
  getMarkOrderMcsDialogIsUpdating,
  getMarkOrderMcsDialogMedicines,
  getMarkOrderMcsDialogOrderId,
  getMarkOrderMcsDialogSelectedMedicines
} from "../../redux/root-reducer";

export const MarkOrderMcsDialog = ({
  open,
  medicines,
  onCancel,
  selectedMedicines,
  canSubmit,
  onSubmit,
  orderId,
  isLoading,
  onCheckboxChange
}) => (
  <Dialog open={open} fullWidth>
    <DialogTitle>Mark order as MCS</DialogTitle>
    <DialogContent>
      <DialogContentText>Select the medicines that are MCS</DialogContentText>
      {medicines.map((medicine, i) => (
        <div key={i}>
          <FormControlLabel
            id={`t_mcs_medicine_${i}`}
            control={
              <Checkbox
                className={`t_medicine_checkbox_${i}`}
                checked={selectedMedicines.includes(i)}
                onChange={() => onCheckboxChange(i, selectedMedicines)}
              />
            }
            label={`${medicine.desc}`}
          />
        </div>
      ))}
    </DialogContent>
    <DialogActions>
      <Button
        id={"t_cancel"}
        onClick={onCancel}
        color={"secondary"}
        disabled={isLoading}
      >
        Cancel
      </Button>
      <LoadingButton
        id={"t_agree"}
        onClick={() => onSubmit(orderId, medicines, selectedMedicines)}
        color={"primary"}
        autoFocus
        disabled={!canSubmit}
        loading={isLoading}
      >
        Mark As MCS
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export const mapDispatchToProps = dispatch => ({
  onCheckboxSelect: (medicine, selectedMedicines) =>
    dispatch(addMedicine(medicine, selectedMedicines)),
  onCheckboxDeselect: (medicine, selectedMedicines) =>
    dispatch(removeMedicine(medicine, selectedMedicines)),
  onCancel: () => dispatch(hideMarkOrderMcs()),
  onSubmit: (orderID, medicines, selectedMedicines) =>
    dispatch(
      window.location.pathname.includes("cf-rejected")
        ? markMCSCFRejected(orderID, medicines, selectedMedicines)
        : markMCS(orderID, medicines, selectedMedicines)
    )
});

export const mapStateToProps = state => ({
  open: getMarkOrderMcsDialogIsOpen(state),
  medicines: getMarkOrderMcsDialogMedicines(state),
  selectedMedicines: getMarkOrderMcsDialogSelectedMedicines(state),
  canSubmit: getMarkOrderMcsDialogCanSubmit(state),
  orderId: getMarkOrderMcsDialogOrderId(state),
  isLoading: getMarkOrderMcsDialogIsUpdating(state)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...stateProps,
  onCheckboxChange: (medicine, selectedMedicines) => {
    const isChecked = selectedMedicines.includes(medicine);
    if (isChecked) {
      dispatchProps.onCheckboxDeselect(medicine, selectedMedicines);
    } else {
      dispatchProps.onCheckboxSelect(medicine, selectedMedicines);
    }
  },
  ...ownProps
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MarkOrderMcsDialog);
