import { useCallback, useEffect, useRef, useState } from "react";
import useKeyStrokeWithTimeout from "./useKeyStrokeWithTimeout";

/*
 * The useBarcodeScans hook emits sequences of key values as strings when recognised as a barcode.
 * Recognition is predicted on the use of a timeout between keys to differentiate human input
 * events from barcode scanning events, as well as a `scanEndKey` to find the end of a barcode.
 *
 * All recognised barcode values are emitted until the returned `reset` func is called which empties
 * the output data structures.
 */
function useBarcodeScans(scanEndKey = "Enter", betweenKeyDownTimeout = 250) {
  const currentScanRef = useRef([]);
  const [allScans, setAllScans] = useState([]);
  const [key, timedOut, restartSequence] = useKeyStrokeWithTimeout(
    betweenKeyDownTimeout
  );

  // Handle key presses
  useEffect(() => {
    if (!key) return;
    const { key: k } = key;
    // Only handle single character keys eg. A, B, C etc
    if (k !== scanEndKey && k.length > 1) return;
    if (k === scanEndKey && !currentScanRef.current.length) return;

    // Emit scans
    if (k === scanEndKey) {
      const scan = currentScanRef.current.join("");
      setAllScans(prev => [...prev, scan]);
      currentScanRef.current = [];
      restartSequence();
      return;
    }
    // Otherwise add to the current scan
    currentScanRef.current.push(k);
  }, [key, timedOut, scanEndKey, allScans, setAllScans, restartSequence]);

  // Handle timeouts
  useEffect(() => {
    if (timedOut) {
      currentScanRef.current = [];
      restartSequence();
    }
  }, [timedOut, restartSequence]);

  const resetScans = () => {
    currentScanRef.current = [];
    setAllScans([]);
  };

  return [allScans, allScans.length, useCallback(resetScans, [])];
}

export default useBarcodeScans;
