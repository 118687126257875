import { handleActions } from "redux-actions";

import {
  CANCEL_ORDERS_RECEIVE,
  CLEAR_ORDER_FILTERS,
  DELETE_MEDICINE_FILTER,
  DELIVER_ORDER_RECEIVE,
  DISPATCH_ORDERS_FAILURE,
  DISPATCH_ORDERS_RECEIVE,
  DISPENSE_ORDERS_RECEIVE,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_PAGED_FAILURE,
  FETCH_ORDERS_PAGED_RECEIVE,
  FETCH_ORDERS_PAGED_REQUEST,
  FETCH_ORDERS_RECEIVE,
  FETCH_ORDERS_REQUEST,
  HM_DELIVER_ORDERS_RECEIVE,
  MARK_ORDER_MCS_RECEIVE,
  PRINT_ORDERS_RECEIVE,
  RESOLVE_ORDER_MCS_RECEIVE,
  SELECT_MEDICINE_FILTER,
  SELECT_ORDERS,
  SELECT_ORDERS_TAB,
  SELECT_ORDERS_SUB_TAB,
  TOGGLE_ORDERS_FILTER,
  UPDATE_ORDER_PRIORITY_RECEIVE,
  RELEASE_ORDER_RECEIVE,
  SELECT_ORDER_SEQUENCE_NUMBER,
  FETCH_SEQ_COUNT_RECEIVE,
  MARK_ND_MCS_RECEIVE,
  UPDATE_ORDER_MCS_RECEIVE_REFRESH,
  READY_TO_PRINT_RECEIVE
} from "./orders.actions";
import { HIDE_CONFIRM_DIALOG } from "../confirm-dialog";
import { DELIVERY_ADDRESS_UPDATE_RECEIVE } from "../delivery-address";
import { CREATE_DISPATCH_LABEL_RECEIVE } from "../dispatch-label/create-dialog";
import {
  REJECT_EVIDENCE_RECEIVE,
  APPROVE_EVIDENCE_RECEIVE
} from "../evidence-dialog";
import { GET_GP_PROFILE_REQUEST, SET_GP_PROFILE_RECEIVE } from "../gp-profile";
import { RETURN_SCRIPT_RECEIVE } from "../return-prescription-dialog";
import { UPDATE_SHIPPING_CLASS_RECEIVE } from "../shipping-class-dialog";
import { SELECT_USER_TAB, UPDATE_USER_PROFILE_RECEIVE } from "../user-profile";
import { MedSuggestion } from "redux/meds-search";
import { LOCATION_CHANGE } from "connected-react-router";
import { MARK_ORDER_CUSTOMER_SERVICE_RECEIVE } from "redux/mark-order-customer-service";

export const byId = handleActions(
  {
    [FETCH_ORDERS_RECEIVE]: (state, action: any) =>
      action.payload.reduce(
        (acc, order) => Object.assign(acc, { [order.id]: order }),
        {}
      ),
    [FETCH_ORDERS_PAGED_RECEIVE]: (state, action: any) =>
      action.payload.reduce(
        (acc, order) => Object.assign(acc, { [order.id]: order }),
        { ...state }
      ),
    [DELIVERY_ADDRESS_UPDATE_RECEIVE]: (state, action: any) => {
      if (!action.meta.orderId) return state;
      const order = state[action.meta.orderId];
      if (!order) return state;
      const { address } = action.meta;
      const deliveryAddress = [
        address.line1,
        address.line2,
        address.city,
        address.postcode
      ].join("\n");
      return {
        ...state,
        [action.meta.orderId]: { ...order, deliveryAddress }
      };
    }
  },
  {}
);

export const allIds = handleActions(
  {
    [FETCH_ORDERS_RECEIVE]: (state, action: any) =>
      action.payload.map(order => order.id),
    [FETCH_ORDERS_PAGED_RECEIVE]: (state, action: any) =>
      state.concat(action.payload.map(order => order.id))
  },
  []
);

export const selectedIds = handleActions(
  {
    [SELECT_ORDERS]: (_state, actions: any) => actions.orders.map(o => o.id),
    [FETCH_ORDERS_REQUEST]: () => [],
    [GET_GP_PROFILE_REQUEST]: () => [],
    [SELECT_USER_TAB]: () => [],
    [SELECT_ORDERS_TAB]: () => [],
    [SELECT_ORDER_SEQUENCE_NUMBER]: () => []
  },
  []
);

export const fetching = handleActions(
  {
    [FETCH_ORDERS_REQUEST]: () => true,
    [FETCH_ORDERS_RECEIVE]: () => false,
    [FETCH_ORDERS_FAILURE]: () => false
  },
  false
);

export const fetchingAdditional = handleActions(
  {
    [FETCH_ORDERS_PAGED_REQUEST]: () => true,
    [FETCH_ORDERS_PAGED_RECEIVE]: () => false,
    [FETCH_ORDERS_PAGED_FAILURE]: () => false
  },
  false
);

export const error = handleActions(
  {
    [FETCH_ORDERS_PAGED_REQUEST]: () => null,
    [FETCH_ORDERS_PAGED_RECEIVE]: () => null,
    [FETCH_ORDERS_PAGED_FAILURE]: (state, action) => action.payload,
    [FETCH_ORDERS_REQUEST]: () => null,
    [FETCH_ORDERS_RECEIVE]: () => null,
    [FETCH_ORDERS_FAILURE]: (state, action) => action.payload
  },
  null
);

export const needsRefresh = handleActions(
  {
    [FETCH_ORDERS_RECEIVE]: () => false,
    [DISPATCH_ORDERS_RECEIVE]: () => true,
    [REJECT_EVIDENCE_RECEIVE]: () => true,
    [APPROVE_EVIDENCE_RECEIVE]: () => true,
    [DISPENSE_ORDERS_RECEIVE]: () => true,
    [DELIVER_ORDER_RECEIVE]: () => true,
    [CANCEL_ORDERS_RECEIVE]: () => true,
    [RELEASE_ORDER_RECEIVE]: () => true,
    [CREATE_DISPATCH_LABEL_RECEIVE]: () => true,
    [SET_GP_PROFILE_RECEIVE]: () => true,
    [UPDATE_USER_PROFILE_RECEIVE]: () => true,
    [PRINT_ORDERS_RECEIVE]: () => true,
    [RETURN_SCRIPT_RECEIVE]: () => true,
    [MARK_ORDER_MCS_RECEIVE]: () => true,
    [RESOLVE_ORDER_MCS_RECEIVE]: () => true,
    [MARK_ND_MCS_RECEIVE]: () => true,
    [UPDATE_SHIPPING_CLASS_RECEIVE]: () => true,
    [UPDATE_ORDER_PRIORITY_RECEIVE]: () => true,
    [SELECT_ORDERS_TAB]: () => true,
    [SELECT_ORDER_SEQUENCE_NUMBER]: () => true,
    [SELECT_ORDERS_SUB_TAB]: () => true,
    [HM_DELIVER_ORDERS_RECEIVE]: () => true,
    [UPDATE_ORDER_MCS_RECEIVE_REFRESH]: () => true,
    [MARK_ORDER_CUSTOMER_SERVICE_RECEIVE]: () => true,
    [READY_TO_PRINT_RECEIVE]: () => true
  },
  true
);

export const resultsPage = handleActions(
  {
    [FETCH_ORDERS_RECEIVE]: () => 1,
    [FETCH_ORDERS_PAGED_RECEIVE]: state => state + 1
  },
  0
);

export const lastPageSize = handleActions(
  {
    [FETCH_ORDERS_RECEIVE]: (_state, action: any) => action.payload.length,
    [FETCH_ORDERS_PAGED_RECEIVE]: (_state, action: any) => action.payload.length
  },
  0
);

export const selectedTabIndex = handleActions(
  {
    [SELECT_ORDERS_TAB]: (_state, action: any) => action.tabIndex || 0
  },
  0
);

export const selectedOrderNumber = handleActions(
  {
    [SELECT_ORDER_SEQUENCE_NUMBER]: (_state, action: any) =>
      action.orderIndex || 1
  },
  1
);

export const selectedSubTabIndex = handleActions(
  {
    [SELECT_ORDERS_SUB_TAB]: (_state, action: any) => action.tabIndex || 0
  },
  0
);

export const willActOnAll = handleActions(
  {
    [SELECT_ORDERS]: (_state, action: any) => action.actOnAll,
    [DISPATCH_ORDERS_RECEIVE]: () => false,
    [DISPATCH_ORDERS_FAILURE]: () => false,
    [HIDE_CONFIRM_DIALOG]: () => false
  },
  false
);

const defaultFiltersState: {
  medicines: MedSuggestion[];
} = {
  medicines: []
};

export const filters = handleActions(
  {
    [SELECT_MEDICINE_FILTER]: (state, action: any) => {
      if (
        state.medicines.some(
          med =>
            med.vmpId === action.medicine.vmpId &&
            med.ampId === action.medicine.ampId
        )
      ) {
        return state;
      }

      return {
        ...state,
        medicines: [...state.medicines, action.medicine]
      };
    },
    [DELETE_MEDICINE_FILTER]: (state, action: any) => {
      if (
        state.medicines.some(
          med =>
            med.vmpId === action.medicine.vmpId &&
            med.ampId === action.medicine.ampId
        )
      ) {
        const filteredMedicines = state.medicines.filter(
          med => med.vmpId !== action.medicine.vmpId
        );
        return {
          ...state,
          medicines: filteredMedicines
        };
      }

      return state;
    },
    [CLEAR_ORDER_FILTERS]: () => defaultFiltersState,
    [LOCATION_CHANGE]: () => defaultFiltersState
  },
  defaultFiltersState
);

export const showFilters = handleActions(
  {
    [TOGGLE_ORDERS_FILTER]: state => !state
  },
  false
);

export const seqCount = handleActions(
  {
    [FETCH_SEQ_COUNT_RECEIVE]: (_state, action: any) => action.payload
  },
  1
);
