import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { apiMiddleware } from "redux-api-middleware";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import rootReducer from "./root-reducer";
import authMiddleware from "./middlewares/auth";
import apiErrorMiddleware from "./middlewares/auth/apiError";
import printMiddleware from "./middlewares/print";
import errorNotificationMiddleware from "./middlewares/error-notification";
import nextActionMiddleware from "./middlewares/next";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [
  authMiddleware,
  apiMiddleware,
  apiErrorMiddleware,
  errorNotificationMiddleware,
  printMiddleware,
  nextActionMiddleware,
  routerMiddleware(history)
];

// if (process.env.NODE_ENV === 'development') {
// Temporary enable dev tools in prod.
const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

if (typeof __REDUX_DEVTOOLS_EXTENSION__ === "function") {
  enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
}
// }

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export const persistor = persistStore(store);

export default store;
