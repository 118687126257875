import { Button, Grid, Tab, Tabs } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import cx from "clsx";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setRightClickMenuOptions } from "../../redux/context-menu";
import { openCreateOrder } from "../../redux/create-order";
import { openDeliveryAddress } from "../../redux/delivery-address";
import { getDescription } from "../../redux/descriptions";
import { PACKAGES } from "../../redux/dispatch-label/create-dialog";
import { showEvidenceDialog } from "../../redux/evidence-dialog";
import {
  fetchAdditionalOrders,
  fetchOrders,
  fetchSeqCount,
  getEvidenceIsApproved,
  selectOrders,
  setOrderSequenceNumber,
  setOrdersTabIndex
} from "../../redux/orders";
import {
  getCreateDispatchLabelIsCreatingLabel,
  getEvidenceIsVisible,
  getOrders,
  getOrdersActingOnAll,
  getOrdersIsFetchingFirstPage,
  getOrdersIsFetchingNextPage,
  getOrdersLastPageSize,
  getOrdersNeedsUpdate,
  getOrdersResultsPage,
  getOrdersSelectedTabIndex,
  getSelectedOrderIds,
  getWhistlEnabled,
  getOrdersMedicineFilters,
  getShowOrdersFilters,
  getOrderSelectedIndex,
  getOrderSeqCount
} from "../../redux/root-reducer";
import * as gpSelectors from "../../redux/selectors/gp";
import { getFormattedStatus } from "../../redux/selectors/order";
import { showUpdateShippingClass } from "../../redux/shipping-class-dialog";
import DeliveryAddressCell from "../common/delivery-address-cell";
import ExemptionCell from "../common/exemption-cell";
import GPDetailsCell from "../common/gp-details-cell";
import InfiniteScroll from "../common/infinite-scroll";
import LatestEventCell from "../common/latest-event-cell";
import MedicinesCell from "../common/medicines-cell";
import NextPageLoader from "../common/next-page-loader";
import NotesCell from "../common/notes-cell";
import PatientDetailsCell from "../common/patient-details-cell";
import ShippingClassCell from "../common/shipping-class-cell";
import StatusCell from "../common/status-cell";
import StatusIcon from "../common/status-icon";
import TrackingInfo from "../common/tracking-info";
import EvidenceDialog from "../evidence-dialog";
import ScreenActionButtons from "../common/screen-action-buttons";

import FilterButton from "./components/filter-button";
import OrdersFilter, { FILTERS_WIDTH } from "./components/orders-filter";
import s from "./style.module.scss";
import {
  CC_ACCEPTED,
  CC_DELIVERED,
  CC_DISPENSED,
  CC_DOWNLOADED,
  CC_NOT_COLLECTED,
  CC_TRANSFERRED
} from "../../redux/click-and-collect";
import {
  getDateFilters,
  getDateFiltersPriority
} from "redux/date-filter-dialog";

export class Orders extends Component {
  static getDisplayDefaultSettings = () => ({
    showOrderStatus: false,
    showPatient: true,
    showGP: true,
    subSplit: true,
    showLatestEvent: true,
    showNotes: false,
    showDeliveryAddress: true,
    showBarcodeColumn: false,
    showShippingClass: true,
    showShippingButtonsColumn: false,
    showOrderId: true
  });

  static propTypes = {
    displaySettings: PropTypes.shape({
      showOrderStatus: PropTypes.bool,
      showPatient: PropTypes.bool,
      showGP: PropTypes.bool,
      showLatestEvent: PropTypes.bool,
      showNotes: PropTypes.bool,
      showDeliveryAddress: PropTypes.bool,
      showBarcodeColumn: PropTypes.bool,
      showBarcodeForOrder: PropTypes.func, // this function takes the order status and returns true (show the barcode) or false.
      showShippingClass: PropTypes.bool,
      showShippingButtonsColumn: PropTypes.bool,
      showShippingButtonsForOrder: PropTypes.func, // this function takes the order status and returns true (show the buttons) or false.
      showOrderId: PropTypes.bool
    }),
    userId: PropTypes.string,
    disableDefaultFetch: PropTypes.bool,
    orders: PropTypes.array.isRequired,
    selectedOrderIds: PropTypes.array.isRequired,

    warningSettings: PropTypes.object,

    isLoadingFirstPage: PropTypes.bool.isRequired,
    isLoadingNextPage: PropTypes.bool.isRequired,
    showEvidenceDialog: PropTypes.bool.isRequired,
    needsUpdate: PropTypes.bool.isRequired,
    canSelectMultiple: PropTypes.bool,
    showEvidenceColumn: PropTypes.bool,
    showUserProfileButton: PropTypes.bool,

    fetchOrders: PropTypes.func.isRequired,
    fetchAdditionalOrders: PropTypes.func.isRequired,
    lastPageSize: PropTypes.number,
    selectOrders: PropTypes.func.isRequired,
    onViewEvidence: PropTypes.func.isRequired,
    onViewGPProfile: PropTypes.func.isRequired,
    onViewUserProfile: PropTypes.func.isRequired,
    setRightClickMenuOptions: PropTypes.func.isRequired,

    contentDescription: PropTypes.string.isRequired,
    filters: PropTypes.array,
    showExpired: PropTypes.bool,
    onClickFab: PropTypes.func,
    hideNewOrderBtn: PropTypes.bool,
    hideDeliveryAddressEditButton: PropTypes.bool,
    onEditDeliveryAddress: PropTypes.func,
    hideShippingClassEditButton: PropTypes.bool,
    onEditShippingClass: PropTypes.func,
    showNotesColumnn: PropTypes.bool,

    splits: PropTypes.number,
    selectedTabIndex: PropTypes.number,
    onTabSelected: PropTypes.func,

    selectedOrderIndex: PropTypes.number,
    onOrderTabSelected: PropTypes.func,
    onFetchSeqCount: PropTypes.func,
    seqCount: PropTypes.object,

    actingOnAll: PropTypes.bool.isRequired,

    whistlEnabled: PropTypes.bool.isRequired,

    onShippingButtonPress: PropTypes.func,
    packages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        weight: PropTypes.number
      })
    ).isRequired,
    shippingLabelLoading: PropTypes.bool,
    tab: PropTypes.string,
    splitAlpha: PropTypes.string
  };

  static defaultProps = {
    displaySettings: Orders.getDisplayDefaultSettings(),
    userId: "",
    disableDefaultFetch: false,
    showUserProfileButton: true,
    canSelectMultiple: false,
    showEvidenceColumn: false,
    allPagesFetched: false,
    warningSettings: null,
    filters: [],
    showExpired: false,
    hideNewOrderBtn: false,
    hideDeliveryAddressEditButton: false,
    hideShippingClassEditButton: false,
    onClickFab: () => {},
    onEditDeliveryAddress: () => {},
    onEditShippingClass: () => {},
    showNotesColumnn: false,
    splits: 0,
    selectedTabIndex: 0,
    onTabSelected: () => {},
    selectedOrderIndex: 1,
    onOrderTabSelected: () => {},
    onFetchSeqCount: () => {},
    actingOnAll: false,
    onShippingButtonPress: () => {},
    shippingLabelLoading: false,
    tab: "",
    splitAlpha: "",
    seqCount: {}
  };

  componentDidMount() {
    this.props.fetchOrders({
      splits: this.getSplits(),
      orderSequence: this.getOrderSequence()
    });
    if (this.props.filters.includes("cf_rejected")) {
      this.props.onFetchSeqCount(this.props.selectedTabIndex - 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dateFilter &&
      prevProps.dateFilter &&
      (prevProps.dateFilter.length !== this.props.dateFilter.length ||
        prevProps.dateFilterPriority != this.props.dateFilterPriority)
    ) {
      setTimeout(() => {
        this.props.fetchOrders({
          splits: this.getSplits(),
          orderSequence: this.getOrderSequence()
        });
      }, 10);
    }
    if (
      (!prevProps.needsUpdate && this.props.needsUpdate) ||
      prevProps.medicines.length !== this.props.medicines.length
    ) {
      this.props.fetchOrders({
        splits: this.getSplits(),
        orderSequence: this.getOrderSequence()
      });
    }
  }

  // componentWillUnmount() {
  //   this.props.onOrderTabSelected(1);
  //   this.props.onTabSelected(0);
  // }

  onRowClicked(order, selectedOrders, isChild) {
    const { canSelectMultiple, selectOrders } = this.props;
    const wasSelected = selectedOrders.includes(order);
    if (canSelectMultiple && wasSelected && !isChild) {
      selectOrders(selectedOrders.filter(o => o !== order));
    } else if (canSelectMultiple && !wasSelected && !isChild) {
      selectOrders([...selectedOrders, order]);
    } else if (wasSelected) {
      selectOrders([]);
    } else {
      selectOrders([order]);
    }
  }

  onToggleSelectAll() {
    if (this.props.selectedOrderIds.length) {
      this.props.selectOrders([]);
    } else {
      this.props.selectOrders([...this.props.orders]);
    }
  }

  onSelectActOnAll() {
    this.props.selectOrders([...this.props.orders], true);
  }

  handleContextMenu(e, order, selectedOrders, isChild) {
    const wasSelected = selectedOrders.includes(order);
    if (e) e.preventDefault();
    if (!wasSelected) this.onRowClicked(order, selectedOrders, isChild);
    this.props.setRightClickMenuOptions({
      isVisible: true,
      x: e.pageX,
      y: e.pageY
    });
  }

  mapMedicineQuantities = (medicines, medicineQuantities) => {
    if (!medicines) {
      return [];
    }
    if (!medicineQuantities) {
      return medicines;
    }
    return medicines.map(function(med) {
      return { ...med, quantity: medicineQuantities[med.vmpId] };
    });
  };

  mapExemptionData = order => {
    const { invoice } = order;
    return {
      exemptions: invoice.exemptionCode.split(",").filter(e => e.length),
      paid: invoice.exemptionCode === "",
      approved: getEvidenceIsApproved(order),
      evidenceSkipped: invoice.exemptionEvidenceSkipped
    };
  };

  mapGpData = gp => ({
    organisationCode: gpSelectors.getId(gp),
    name: gpSelectors.getName(gp),
    address: gpSelectors.getFullAddress(gp),
    phone: gpSelectors.getTelephone(gp)
  });

  isCCPage = () =>
    this.props.filters.filter(filter =>
      [
        CC_ACCEPTED,
        CC_TRANSFERRED,
        CC_DOWNLOADED,
        CC_DISPENSED,
        CC_NOT_COLLECTED
      ].includes(filter)
    ).length > 0;

  renderTableRow(
    index,
    order,
    isSelected,
    selectedOrders,
    haveNested,
    isChild
  ) {
    const {
      showEvidenceColumn,
      warningSettings,
      onViewGPProfile,
      onViewUserProfile,
      showUserProfileButton,
      displaySettings,
      hideDeliveryAddressEditButton,
      onEditDeliveryAddress,
      hideShippingClassEditButton,
      onEditShippingClass,
      showNotesColumn,
      whistlEnabled,
      userId
    } = this.props;
    return (
      <>
        <TableRow
          id={`t_row_${index}`}
          key={index}
          className={cx(
            "t_row",
            s.row,
            order.mcs ? s.mcsrow : null,
            order.priority ? s.priorityrow : null,
            haveNested ? s.nestedRow : null,
            isChild ? s.nestedChildRow : null
          )}
          onContextMenu={e =>
            this.handleContextMenu(e, order, selectedOrders, isChild)
          }
          onClick={() => this.onRowClicked(order, selectedOrders, isChild)}
          selected={isSelected}
        >
          {this.renderBodyCheckboxIfNeeded(
            index,
            isSelected,
            order,
            selectedOrders,
            isChild
          )}
          {displaySettings.showOrderId ? (
            <TableCell className={"t_order_id"}>
              {order.parentOrderId ? (
                <>
                  #{order.parentOrderId}
                  <br />
                </>
              ) : null}
              #{order.id}
              {order.mcs ? <p className={cx(s.mcslabel)}>MCS</p> : null}
              {order.priority ? (
                <p className={cx(s.prioritylabel)}>Priority</p>
              ) : null}
              {order.batchId ? (
                <p className={cx(s.batchlabel)}>
                  <GroupWorkIcon fontSize={"small"} />
                  <span>{order.batchId.slice(-4)}</span>
                </p>
              ) : null}
              {order.orderSeqNumber ? (
                <p className={cx(s.sequenceNumber)}>
                  Customer order number: {order.orderSeqNumber}
                </p>
              ) : null}
            </TableCell>
          ) : null}
          {displaySettings.showOrderStatus ? (
            <TableCell id={`t_status_${index}`} className={"t_request_status"}>
              <div style={{ display: "flex" }}>
                <StatusIcon status={order.status} />
                {getFormattedStatus(order)}
              </div>
            </TableCell>
          ) : null}
          {warningSettings ? (
            <TableCell className={s.statusCell}>
              <StatusCell
                warningSettings={warningSettings}
                lastModifiedDate={order.lastModifiedDate}
              />
            </TableCell>
          ) : null}
          {displaySettings.showBarcodeColumn && (
            <TableCell className={`t_barcode_cell`}>
              {displaySettings.showBarcodeForOrder(order.status) ? (
                <QRCode
                  id={`t_barcode_${index}`}
                  size={100}
                  value={`${order.id}`}
                />
              ) : (
                <div
                  id={`t_barcode_placeholder_${index}`}
                  style={{ width: 100, height: 100 }}
                />
              )}
            </TableCell>
          )}
          {displaySettings.showShippingButtonsColumn && (
            <TableCell className={`t_shipping_button_cell`}>
              {displaySettings.showShippingButtonsForOrder(order.status) ? (
                <Grid
                  container
                  justify={"center"}
                  direction={"column"}
                  spacing={8}
                >
                  {this.props.packages.map(p => (
                    <Grid key={p.value} item>
                      <Button
                        fontSize={5}
                        disabled={this.props.shippingLabelLoading}
                        className={cx(`t_shipping_button ${s.shippingbutton}`)}
                        id={`t_shipping_button_${p.value}`}
                        variant={"contained"}
                        onClick={() => {
                          this.props.onShippingButtonPress({
                            orderId: order.id,
                            shippingPackage: p,
                            whistlEnabled
                          });
                        }}
                      >
                        <span id={`t_shipping_button_label_${p.value}`}>
                          {p.name === "Large Letter" ? "LL" : p.name}
                        </span>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </TableCell>
          )}
          {displaySettings.showPatient ? (
            <TableCell>
              {order.patient?.userId && (
                <PatientDetailsCell
                  id={`t_patient_details_${index}`}
                  {...order.patient}
                  onViewButtonClick={
                    showUserProfileButton
                      ? () => onViewUserProfile(order)
                      : null
                  }
                />
              )}
            </TableCell>
          ) : null}
          {displaySettings.showShippingClass ? (
            <TableCell>
              {(!userId || !haveNested) && (
                <>
                  <ShippingClassCell
                    id={`t_shipping_class_${index}`}
                    shippingClass={`${order.shippingClass}`}
                    shippingSigned={order.shippingSigned}
                    showEditButton={!hideShippingClassEditButton}
                    onEditButtonClicked={() => onEditShippingClass(order)}
                  />
                  {order.courier && (
                    <TrackingInfo {...order} id={`t_tracking_info_${index}`} />
                  )}
                </>
              )}
            </TableCell>
          ) : null}
          {displaySettings.showDeliveryAddress && (
            <TableCell>
              {(!userId || !haveNested) && (
                <DeliveryAddressCell
                  id={`t_delivery_address_${index}`}
                  onEditButtonClicked={() => onEditDeliveryAddress(order)}
                  pharmacyId={this.isCCPage() ? order.ccBranchId : undefined}
                  showEditButton={!hideDeliveryAddressEditButton}
                >
                  {order.deliveryAddress
                    ? `${order.deliveryAddress}${
                        this.isCCPage() ? `\n[ ${order.ccBranchId} ]` : ``
                      }`
                    : ""}
                </DeliveryAddressCell>
              )}
            </TableCell>
          )}
          <TableCell>
            <MedicinesCell
              medicines={this.mapMedicineQuantities(
                order.medicines,
                order.medicineQuantities
              )}
            />
          </TableCell>
          {showNotesColumn ? (
            <TableCell>
              <NotesCell {...order} id={"t_notes_col"} />
            </TableCell>
          ) : null}
          {displaySettings.showGP ? (
            <TableCell>
              <GPDetailsCell
                id={`t_gp_details_${index}`}
                {...this.mapGpData(order.gp)}
                onClick={onViewGPProfile}
              />
            </TableCell>
          ) : null}
          {showEvidenceColumn ? (
            <TableCell>
              <ExemptionCell
                id={"t_evidence"}
                {...this.mapExemptionData(order)}
                onViewEvidence={() => this.props.onViewEvidence(order)}
              />
            </TableCell>
          ) : null}
          {displaySettings.showLatestEvent ? (
            <TableCell>
              <LatestEventCell
                id={`t_latest_event_${index}`}
                warningSettings={this.props.warningSettings}
                lastEventName={order.lastEventName}
                lastModifiedDate={order.lastModifiedDate}
              />
            </TableCell>
          ) : null}
        </TableRow>
        {haveNested && (
          <span className={s.nestedRowBubble}>
            {userId ? "Child orders" : "Other items in order"}
          </span>
        )}
      </>
    );
  }

  renderBodyCheckboxIfNeeded(index, isChecked, order, selectedOrders, isChild) {
    if (!this.props.canSelectMultiple) return null;
    return (
      <TableCell padding={"checkbox"}>
        {!isChild && (
          <Checkbox
            id={`t_checkbox_${index}`}
            className={"t_checkbox"}
            checked={isChecked}
            onChange={() => this.onRowClicked(order, selectedOrders, isChild)}
          />
        )}
      </TableCell>
    );
  }

  renderBodyRows(orders, selectedOrderIds) {
    const selectedOrders = this.props.orders.filter(o =>
      selectedOrderIds.includes(o.id)
    );

    const items = orders.map((order, i) => {
      const haveNested = order.nestedOrders?.length > 0;
      return (
        <Fragment key={i}>
          {this.renderTableRow(
            i,
            order,
            selectedOrderIds.includes(order.id),
            selectedOrders,
            haveNested
          )}
          {order.nestedOrders?.length > 0 &&
            order.nestedOrders.map((nestedOrder, j) =>
              this.renderTableRow(
                j,
                nestedOrder,
                selectedOrderIds.includes(nestedOrder.id),
                selectedOrders,
                false,
                true
              )
            )}
        </Fragment>
      );
    });

    return (
      <InfiniteScroll
        onReachThreshold={
          this.props.lastPageSize === this.props.ordersPerPage
            ? () =>
                this.props.fetchAdditionalOrders({ splits: this.getSplits() })
            : () => {}
        }
        isLoading={
          this.props.isLoadingFirstPage || this.props.isLoadingNextPage
        }
      >
        {items}
      </InfiniteScroll>
    );
  }

  renderHeaderCheckboxIfNeeded() {
    if (!this.props.canSelectMultiple) return null;
    const allRowsSelected =
      this.props.orders.length === this.props.selectedOrderIds.length &&
      this.props.orders.length > 0;
    const indeterminate =
      this.props.orders.length !== this.props.selectedOrderIds.length &&
      this.props.selectedOrderIds.length > 0;
    return (
      <TableCell padding={"checkbox"}>
        <Checkbox
          id={"t_checkbox_all"}
          className={"t_checkbox"}
          indeterminate={indeterminate}
          checked={allRowsSelected}
          onChange={(e, checked) => this.onToggleSelectAll(checked)}
        />
        {allRowsSelected && (
          <Button
            id={"t_act_on_all"}
            onClick={() => this.onSelectActOnAll()}
            color={"secondary"}
            variant={this.props.actingOnAll ? "outlined" : "text"}
          >
            {this.props.actingOnAll
              ? "ALL ELIGIBLE selected"
              : "Select ALL ELIGIBLE"}
          </Button>
        )}
      </TableCell>
    );
  }

  getSplits() {
    const urlParams = new URLSearchParams(window.location.search);
    const v = parseInt(urlParams.get("splits"));
    if (v > 0) {
      return v;
    }
    return this.props.splits;
  }

  getOrderSequence() {
    return this.props.selectedOrderIndex;
  }

  isReadyToPrint() {
    return this.props.selectedOrderIndex;
  }

  renderWithTabs = () => {
    const {
      selectedTabIndex,
      onTabSelected,
      selectedOrderIndex,
      seqCount,
      onOrderTabSelected,
      onFetchSeqCount,
      filters
    } = this.props;
    const splits = this.getSplits();
    return (
      <div className={s.tabs}>
        <Tabs
          value={selectedTabIndex}
          indicatorColor={"primary"}
          textColor={"primary"}
          onChange={(e, v) => {
            e.preventDefault();
            onTabSelected(v);
            onOrderTabSelected("1");
            if (filters.includes("cf_rejected")) {
              onFetchSeqCount(v - 1);
            }
          }}
          fullWidth
        >
          <Tab label={"All"} value={0} id={"t_tab_all"} />
          {Array.from({ length: splits }, (_v, index) => (
            <Tab
              key={`${index}`}
              label={`List ${index + 1}`}
              value={index + 1}
              id={`t_tab_list_${index + 1}`}
            />
          ))}
          )
        </Tabs>
        <Tabs
          variant={"fullWidth"}
          indicatorColor={"primary"}
          textColor={"primary"}
          value={parseInt(selectedOrderIndex)}
          onChange={(e, v) => {
            e.preventDefault();
            onOrderTabSelected(v);
          }}
        >
          <Tab label={`1ST ORDER (${seqCount.seqOne})`} value={1} />
          <Tab label={`2ND ORDER (${seqCount.seqTwo})`} value={2} />
          <Tab label={`3RD ORDER (${seqCount.seqThree})`} value={3} />
          <Tab label={`4TH+ ORDER (${seqCount.seqThreePlus})`} value={4} />
        </Tabs>
        {this.renderTable()}
      </div>
    );
  };

  renderNoDataContent = () => (
    <div className={s.noData} id={"#t_noData"}>
      <h2>
        There are currently no{" "}
        {this.props.contentDescription ||
          `${getFormattedStatus({
            status: this.props.filters[0]
          })} orders`}
        .
      </h2>
    </div>
  );

  renderContent() {
    if (this.props.isLoadingFirstPage) {
      return (
        <div className={s.progress}>
          <CircularProgress id={"t_progress"} size={50} />
        </div>
      );
    }
    if (this.props.orders.length < 1) {
      return (
        <>
          {this.props?.match?.path === "/orders/cf-rejected"
            ? this.renderWithTabs()
            : ""}
          {this.renderNoDataContent()}
        </>
      );
    }
    if (this.getSplits()) {
      return this.renderWithTabs();
    }
    return this.renderTable();
  }

  renderTable() {
    const {
      warningSettings,
      showEvidenceColumn,
      displaySettings,
      orders,
      selectedOrderIds,
      isLoadingNextPage,
      showNotesColumn,
      filters
    } = this.props;

    let parsedOrders = orders;

    if (filters.includes(CC_DOWNLOADED)) {
      parsedOrders = orders.sort((a, b) => {
        if (a.status === CC_DOWNLOADED) {
          return b.status !== CC_DOWNLOADED ? -1 : 0;
        } else if (a.status === CC_DISPENSED) {
          if (b.status === CC_DOWNLOADED) {
            return 1;
          } else if (b.status === CC_DISPENSED) {
            return 0;
          } else {
            return -1;
          }
        } else if (a.status === CC_DELIVERED) {
          return b.status === CC_DOWNLOADED || b.status === CC_DISPENSED
            ? 1
            : 0;
        } else if (a.status === CC_NOT_COLLECTED) {
          return b.status !== CC_NOT_COLLECTED ? -1 : 0;
        }
      });
    }
    //comment

    return (
      <>
        {parsedOrders.length > 0 ? (
          <>
            <Table
              id={"t_table"}
              stickyHeader
              style={{ backgroundColor: "white" }}
            >
              <TableHead>
                <TableRow>
                  {this.renderHeaderCheckboxIfNeeded()}
                  {displaySettings.showOrderId ? (
                    <TableCell>ID</TableCell>
                  ) : null}
                  {displaySettings.showOrderStatus ? (
                    <TableCell id={"t_status_title"}>Status</TableCell>
                  ) : null}
                  {warningSettings ? (
                    <TableCell className={s.statusCell}>Status</TableCell>
                  ) : null}
                  {displaySettings.showBarcodeColumn ? (
                    <TableCell id={`t_barcode_title`}>Barcode</TableCell>
                  ) : null}
                  {displaySettings.showShippingButtonsColumn ? (
                    <TableCell id={`t_shipping_buttons_title`}>
                      Print a label
                    </TableCell>
                  ) : null}
                  {displaySettings.showPatient ? (
                    <TableCell id={"t_patient_details_title"}>Name</TableCell>
                  ) : null}
                  {displaySettings.showShippingClass ? (
                    <TableCell id={"t_shipping_class_title"}>
                      Shipping Class
                    </TableCell>
                  ) : null}
                  {displaySettings.showDeliveryAddress ? (
                    <TableCell id={"t_delivery_address_title"}>
                      Delivery Address
                    </TableCell>
                  ) : null}
                  <TableCell>Medicines</TableCell>
                  {showNotesColumn ? (
                    <TableCell id={"t_notes_title"}>
                      Notes &amp; Allergies
                    </TableCell>
                  ) : null}
                  {displaySettings.showGP ? (
                    <TableCell id={"t_gp_details_title"}>GP Practice</TableCell>
                  ) : null}
                  {showEvidenceColumn ? (
                    <TableCell>Exemption/Payment</TableCell>
                  ) : null}
                  {displaySettings.showLatestEvent ? (
                    <TableCell id={"t_latest_event_title"}>
                      Latest Update
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.renderBodyRows(parsedOrders, selectedOrderIds)}
              </TableBody>
            </Table>
            {this.props.hideNewOrderBtn === false ? (
              <Fab
                className={s.createOrderBtn}
                id={"t_fab"}
                style={{
                  right: this.props.isFiltersOpen ? FILTERS_WIDTH + 24 : 24,
                  bottom: 24,
                  position: "fixed"
                }}
                color={"primary"}
                onClick={() => this.props.onClickFab()}
              >
                <AddIcon style={{ color: "white" }} />
              </Fab>
            ) : null}
            {isLoadingNextPage && <NextPageLoader id={"t_nextPageLoader"} />}
          </>
        ) : null}
      </>
    );
  }

  render() {
    const { showEvidenceDialog } = this.props;
    return (
      <div className={s.root}>
        <ScreenActionButtons>
          <FilterButton />
        </ScreenActionButtons>
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div
            style={{
              flex: 1,
              height: "100%",
              overflowY: "auto",
              position: "relative"
            }}
          >
            {this.renderContent()}
          </div>
          {!this.props.location?.pathname.startsWith("/user") ? (
            <OrdersFilter />
          ) : (
            ""
          )}
        </div>
        {showEvidenceDialog && <EvidenceDialog id={"t_evidenceDialog"} />}
      </div>
    );
  }
}

export const mapDispatchToProps = {
  internalFetchOrders: fetchOrders,
  internalFetchAdditionalOrders: fetchAdditionalOrders,
  selectOrders,
  setRightClickMenuOptions,
  onViewEvidence: showEvidenceDialog,
  pushRoute: push,
  onClickFab: openCreateOrder,
  openDeliveryAddress: openDeliveryAddress,
  showUpdateShippingClass,
  onTabSelected: setOrdersTabIndex,
  onOrderTabSelected: setOrderSequenceNumber,
  onFetchSeqCount: fetchSeqCount
};

export const mapStateToProps = state => ({
  isLoadingFirstPage: getOrdersIsFetchingFirstPage(state),
  isLoadingNextPage: getOrdersIsFetchingNextPage(state),
  showEvidenceDialog: getEvidenceIsVisible(state),
  isFiltersOpen: getShowOrdersFilters(state),
  orders: getOrders(state),
  medicines: getOrdersMedicineFilters(state),
  selectedOrderIds: getSelectedOrderIds(state),
  needsUpdate: getOrdersNeedsUpdate(state),
  contentDescription: getDescription(state),
  currentPage: getOrdersResultsPage(state),
  lastPageSize: getOrdersLastPageSize(state),
  selectedTabIndex: getOrdersSelectedTabIndex(state),
  selectedOrderIndex: getOrderSelectedIndex(state),
  seqCount: getOrderSeqCount(state),
  actingOnAll: getOrdersActingOnAll(state),
  packages: PACKAGES,
  shippingLabelLoading: getCreateDispatchLabelIsCreatingLabel(state),
  whistlEnabled: getWhistlEnabled(state),
  dateFilter: getDateFilters(state),
  dateFilterPriority: getDateFiltersPriority(state)
});
export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onViewUserProfile: order =>
    dispatchProps.pushRoute(`/user/${order.patient.userId}`),
  onViewGPProfile: id => {
    dispatchProps.pushRoute(`/gp/profile/${id}`);
  },
  fetchOrders: ({ splits }) => {
    const hasMedicines = stateProps.medicines.length > 0;
    const dateFilter = stateProps.dateFilter;
    const dateFilterPriority = stateProps.dateFilterPriority;
    const vmpIds = hasMedicines
      ? stateProps.medicines.map(med => med.vmpId)
      : [];
    const ampIds = hasMedicines
      ? stateProps.medicines.map(med => med.ampId)
      : [];
    return ownProps.disableDefaultFetch
      ? () => {}
      : dispatchProps.internalFetchOrders(
          ownProps.filters,
          ownProps.showExpired,
          vmpIds,
          ampIds,
          dateFilter,
          dateFilterPriority,
          ownProps.userId,
          ownProps.ordersPerPage,
          stateProps.selectedTabIndex !== 0 ? splits || 0 : 0,
          stateProps.selectedTabIndex - 1,
          stateProps.selectedOrderIndex,
          ownProps.tab,
          ownProps.splitAlpha
        );
  },
  fetchAdditionalOrders: ({ splits } = {}) => {
    const hasMedicines = stateProps.medicines.length > 0;
    const dateFilter = stateProps.dateFilter;
    const dateFilterPriority = stateProps.dateFilterPriority;
    const vmpIds = hasMedicines
      ? stateProps.medicines.map(med => med.vmpId)
      : [];
    const ampIds = hasMedicines
      ? stateProps.medicines.map(med => med.ampId)
      : [];
    return dispatchProps.internalFetchAdditionalOrders(
      stateProps.currentPage,
      ownProps.filters,
      ownProps.showExpired,
      vmpIds,
      ampIds,
      dateFilter,
      dateFilterPriority,
      ownProps.userId,
      ownProps.ordersPerPage,
      stateProps.selectedTabIndex !== 0 ? splits || 0 : 0,
      stateProps.selectedTabIndex - 1,
      stateProps.selectedOrderIndex,
      ownProps.tab,
      ownProps.splitAlpha
    );
  },
  onEditDeliveryAddress: order =>
    dispatchProps.openDeliveryAddress({
      address: order.deliveryAddress,
      orderId: order.id
    }),
  onEditShippingClass: order =>
    dispatchProps.showUpdateShippingClass({
      orderId: order.id,
      shippingClass: order.shippingClass,
      shippingSigned: order.shippingSigned
    }),
  ...dispatchProps,
  ...stateProps,
  ...ownProps
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Orders)
);
