import { handleActions } from "redux-actions";
import { CONTACT_REQUEST_RECEIVE } from "redux/contact-dialog";
import { CREATE_ORDER_RECEIVE } from "redux/create-order";
import { SET_GP_PROFILE_RECEIVE } from "redux/gp-profile";
import {
  DISPATCH_ORDERS_RECEIVE,
  DELIVER_ORDER_RECEIVE,
  DISPENSE_ORDERS_RECEIVE,
  CANCEL_ORDERS_RECEIVE,
  PRINT_ORDERS_RECEIVE,
  HM_DELIVER_ORDERS_RECEIVE,
  RELEASE_ORDER_RECEIVE,
  UPDATE_ORDER_MCS_RECEIVE_REFRESH,
  MARK_ND_MCS_RECEIVE,
  READY_TO_PRINT_RECEIVE
} from "redux/orders";
import { NOMINATE_USER_RECEIVE } from "redux/pending-users";
import { SEND_TO_GP_RECEIVE, REJECT_REQUEST_RECEIVE } from "redux/requests";
import { RETURN_SCRIPT_RECEIVE } from "redux/return-prescription-dialog";
import {
  FETCH_STATUS_COUNTS_SUCCESS,
  FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE,
  FETCH_ROBOT_COUNTS_SUCCESS,
  FETCH_STATUS_COUNTS_FAILURE,
  FETCH_ROBOT_COUNTS_FAILURE
} from "./navigation.actions";
import { CREATE_DISPATCH_LABEL_RECEIVE } from "redux/dispatch-label/create-dialog";
import { MARK_ORDER_CUSTOMER_SERVICE_RECEIVE } from "redux/mark-order-customer-service";

export const statusCounts = handleActions(
  {
    [FETCH_STATUS_COUNTS_SUCCESS]: (state, action: any) => {
      return { ...state, ...action.payload };
    },
    [FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE]: (state, action: any) => {
      return { ...state, gpContactReview: action.payload.count };
    },
    [FETCH_STATUS_COUNTS_FAILURE]: () => null
  },
  {}
);

export const robotCounts = handleActions(
  {
    [FETCH_ROBOT_COUNTS_SUCCESS]: (state, action: any) => {
      return { ...state, ...action.payload };
    },
    [FETCH_ROBOT_COUNTS_FAILURE]: () => null
  },
  {}
);

export const needsRefresh = handleActions(
  {
    [FETCH_STATUS_COUNTS_SUCCESS]: () => false,
    [SEND_TO_GP_RECEIVE]: () => true,
    [READY_TO_PRINT_RECEIVE]: () => true,
    [REJECT_REQUEST_RECEIVE]: () => true,
    [DISPATCH_ORDERS_RECEIVE]: () => true,
    [DELIVER_ORDER_RECEIVE]: () => true,
    [DISPENSE_ORDERS_RECEIVE]: () => true,
    [CANCEL_ORDERS_RECEIVE]: () => true,
    [RELEASE_ORDER_RECEIVE]: () => true,
    [NOMINATE_USER_RECEIVE]: () => true,
    [CONTACT_REQUEST_RECEIVE]: () => true,
    [CREATE_ORDER_RECEIVE]: () => true,
    [PRINT_ORDERS_RECEIVE]: () => true,
    [RETURN_SCRIPT_RECEIVE]: () => true,
    [HM_DELIVER_ORDERS_RECEIVE]: () => true,
    [UPDATE_ORDER_MCS_RECEIVE_REFRESH]: () => true,
    [MARK_ND_MCS_RECEIVE]: () => true,
    [CREATE_DISPATCH_LABEL_RECEIVE]: () => true,
    [MARK_ORDER_CUSTOMER_SERVICE_RECEIVE]: () => true
  },
  false
);

export const needsGPRefresh = handleActions(
  {
    [SET_GP_PROFILE_RECEIVE]: () => true,
    [FETCH_GP_CONTACT_REVIEW_COUNT_RECEIVE]: () => false
  },
  false
);
