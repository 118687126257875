import Visibility from "@material-ui/icons/Visibility";
import Mailbox from "@material-ui/icons/MarkunreadMailbox";
import Assignment from "@material-ui/icons/Assignment";
import Send from "@material-ui/icons/Send";
import Phone from "@material-ui/icons/Phone";
import Cancel from "@material-ui/icons/Cancel";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import Print from "@material-ui/icons/Print";
import Power from "@material-ui/icons/PowerSettingsNew";
import Event from "@material-ui/icons/Event";
import Email from "@material-ui/icons/Email";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowRight from "@material-ui/icons/ArrowForward";
import Backup from "@material-ui/icons/Backup";
import Block from "@material-ui/icons/Block";
import Done from "@material-ui/icons/Done";
import AddCommentIcon from "@material-ui/icons/AddComment";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import LowPriority from "@material-ui/icons/LowPriority";
import LocalShipping from "@material-ui/icons/LocalShipping";

export default {
  "view-evidence": Visibility,
  "mark-dispensed": Mailbox,
  "convert-request": Assignment,
  "mark-dispatched": Send,
  "mark-delivered": AssignmentTurnedIn,
  "send-request-to-gp": Phone,
  "mark-cancelled": Cancel,
  "reject-request": Cancel,
  "print-dispatch-label": Print,
  "open-contact-dialog": Print,
  "sign-out": Power,
  "view-events": Event,
  "nominate-update-details": Email,
  "nominate-user": CheckCircle,
  "print-pmr": CheckCircle,
  "return-prescription": Backup,
  "print-raw-delivery-address": ArrowRight,
  "mark-mcs": Block,
  "mark-cs": Block,
  "resolve-mcs": Done,
  "update-mcs": AddCommentIcon,
  "resolve-cs": Done,
  "update-cs": AddCommentIcon,
  "update-priority-high": PriorityHigh,
  "update-priority-low": LowPriority,
  "mark-hm-delivered": LocalShipping,
  "transfer-prescription": Send, // TODO: find better icon
  "release-order": Backup,
  "nd-mcs": Cancel,
  "update-mcs-pending": Cancel,
  "ready-to-print": Print
};
